import React from "react";
import ReactDOM from "react-dom";

import Auth from "../modules/firebase/auth";
import { Base } from "../modules/plugins/base"
import Firestore from "../modules/firebase/firestore";

class Authentication extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: false,
    };
  }
  componentDidMount() {
    Auth.onAuthStateChanged(this.onAuthStateChanged);
    // setInterval(() => console.log(new Date().toLocaleTimeString()), 1000);
    // Auth.signInGoogleWithPopup("/");
  }

  // For Auth
  onAuthStateChanged = (user) => {
    if (user) {
      this.setState({ user: user.auth.currentUser });
    } else {
      this.setState({ user: false });
    }
  };

  // componentDidUpdate() {
  //   console.log(this.state.user);
  // }

  render() {
    return (
      <div className='position-relative'>
        {/* <button className='btn btn-primary'>Menu</button> */}
        <div className='position-absolute' style={{ left: "70%", top: "20%" }}>
          {this.state.user ? (
            <div className='dropdown'>
              <div className='dropdown-toggle' data-bs-toggle='dropdown' id='profile-menu' style={{ cursor: "pointer" }}>
                <span>{this.state.user.displayName} </span>
                <img className='border rounded-circle' style={{ height: "2rem", width: "2rem" }} src={this.state.user.photoURL} />
              </div>
              <ul className='dropdown-menu' aria-labelledby='profile-menu'>
                <li>
                  <a className='dropdown-item' href='/profile'>
                    Profile
                  </a>
                </li>
                <li>
                  <hr className='dropdown-divider'></hr>
                </li>
                <li
                  onClick={(event) => {
                    console.log("out");
                    Auth.signOut("/");
                  }}
                >
                  <a className='dropdown-item'>Logout</a>
                </li>
              </ul>
            </div>
          ) : (
            <div>
              <span style={{ cursor: "pointer" }} onClick={(event) => Auth.signInGoogleWithPopup("/")}>
                Login/Register
              </span>
            </div>
          )}
        </div>
      </div>
    );
  }
}

if (typeof window !== "undefined") {
  ReactDOM.hydrate(<Authentication />, document.getElementById("wrapper-header"));
}

export default Authentication;
